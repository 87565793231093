var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-content-box",attrs:{"id":"password-login-box"}},[_c('div',{staticClass:"login-right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"lright-cont"},[_c('div',{staticClass:"login-content"},[_c('div',{staticClass:"login"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmitLogin}},[_c('div',{staticClass:"drop-down d-quhao"},[_c('img',{staticStyle:{"margin-bottom":"2px"},attrs:{"src":require("../../../assets/img/code/phone_number_logo.png"),"alt":""}}),_c('CountryCodePicker',{model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'phoneNumber',
                      {
                        rules: [{ validator: _vm.phoneNumber }],
                      },
                    ]),expression:"[\n                      'phoneNumber',\n                      {\n                        rules: [{ validator: phoneNumber }],\n                      },\n                    ]"}],ref:"phoneNumber",staticClass:"login-phone-number",attrs:{"oninput":"if(value.length>11)value=value.slice(0,11);value=value.replace(/[^\\d]/g,'');","placeholder":_vm.getString(_vm.strings.User_Lolgin_Please_Enter_Phone_Number),"allow-clear":""},on:{"blur":_vm.phoneNumberBlurForValidate}})],1),_c('div',{staticClass:"drop-down"},[_c('img',{attrs:{"src":require("../../../assets/img/code/pwd_input_logo.png"),"alt":""}})]),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'password',
                      {
                        rules: [
                          {
                            required: true,
                            message: _vm.getString(
                              _vm.strings.Input_Please_Enter_The_Password
                            ),
                          },
                          {
                            validator: _vm.validateToNextPassword,
                          },
                        ],
                      },
                    ]),expression:"[\n                      'password',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: getString(\n                              strings.Input_Please_Enter_The_Password\n                            ),\n                          },\n                          {\n                            validator: validateToNextPassword,\n                          },\n                        ],\n                      },\n                    ]"}],ref:"password",attrs:{"oninput":"if(value.length>24)value=value.slice(0,24)","placeholder":_vm.getString(_vm.strings.User_Lolgin_Please_Enter_Password),"type":"password"},on:{"blur":_vm.passwordBlurForValidate}})],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-button',{staticClass:"Login-Button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.User_Lolgin_Login))+" ")])],1),_c('div',_vm._b({staticClass:"Login-Button lbtwo"},'div',_vm.tailFormItemLayout,false),[_c('a',{staticClass:"mailbox-login ho-a",attrs:{"href":"javascript:;"},on:{"click":_vm.mailboxLogin}},[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.Mailbox_Login))+" ")]),_c('a',{staticClass:"forgot-password ho-a",attrs:{"href":"javascript:;"},on:{"click":_vm.ForgetPassWorld}},[_vm._v(_vm._s(_vm.getString(_vm.strings.User_Lolgin_Forgot_Password))+" ")])])],1)],1)]),_c('div',{staticClass:"Login-code",on:{"click":_vm.ClickShowCode}},[_c('img',{attrs:{"src":require("../../../assets/img/code/qrcode9.png"),"alt":""}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }