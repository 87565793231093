<template>
<!-- 密码登录 -->
  <div class="login-content-box" id="password-login-box">
    <div class="login-right">
      <div class="row">
        <div class="lright-cont">
          <!-- <div class="Login-header">
            <div class="Login-container">
              <div class="lc-titel">
                <h2>{{ getString(strings.User_Lolgin_Welcome_To_Login) }}</h2>
                <div class="lc-titel-a">
                  <a
                    href="javascript:;"
                    @click="ClickLoginShow"
                    class="sign-a ho-a"
                    >{{ getString(strings.User_Lolgin_Free_Registration) }}</a
                  >
                </div>
              </div>
            </div>
          </div> -->
          <div class="login-content">
            <div class="login">
              <a-form :form="form" @submit="handleSubmitLogin">
                <div class="drop-down d-quhao">
                  <img
                    src="../../../assets/img/code/phone_number_logo.png"
                    alt=""
                    style="margin-bottom: 2px"
                  />
                  <CountryCodePicker v-model="countryCode" />
                </div>
                <a-form-item>
                  <a-input
                    class="login-phone-number"
                    oninput="if(value.length>11)value=value.slice(0,11);value=value.replace(/[^\d]/g,'');"
                    :placeholder="
                      getString(strings.User_Lolgin_Please_Enter_Phone_Number)
                    "
                    ref="phoneNumber"
                    @blur="phoneNumberBlurForValidate"
                    allow-clear
                    v-decorator="[
                      'phoneNumber',
                      {
                        rules: [{ validator: phoneNumber }],
                      },
                    ]"
                  >
                  </a-input>
                </a-form-item>
                <div class="drop-down">
                  <img
                    src="../../../assets/img/code/pwd_input_logo.png"
                    alt=""
                  />
                </div>
                <a-form-item>
                  <a-input-password
                    oninput="if(value.length>24)value=value.slice(0,24)"
                    :placeholder="
                      getString(strings.User_Lolgin_Please_Enter_Password)
                    "
                    ref="password"
                    @blur="passwordBlurForValidate"
                    v-decorator="[
                      'password',
                      {
                        rules: [
                          {
                            required: true,
                            message: getString(
                              strings.Input_Please_Enter_The_Password
                            ),
                          },
                          {
                            validator: validateToNextPassword,
                          },
                        ],
                      },
                    ]"
                    type="password"
                  >
                    <!-- <a-icon type="lock" slot="addonBefore" /> -->
                  </a-input-password>
                </a-form-item>

                <a-form-item v-bind="tailFormItemLayout">
                  <a-button
                    class="Login-Button"
                    type="primary"
                    html-type="submit"
                  >
                    {{ getString(strings.User_Lolgin_Login) }}
                  </a-button>
                </a-form-item>
                <div v-bind="tailFormItemLayout" class="Login-Button lbtwo">
                  <!-- 邮箱登录 -->
                  <a
                    href="javascript:;"
                    @click="mailboxLogin"
                    class="mailbox-login ho-a"
                  >
                    {{ getString(strings.Mailbox_Login) }}
                  </a>
                  <a
                    href="javascript:;"
                    @click="ForgetPassWorld"
                    class="forgot-password ho-a"
                    >{{ getString(strings.User_Lolgin_Forgot_Password) }}
                  </a>
                </div>
              </a-form>
            </div>
          </div>
          <div class="Login-code" @click="ClickShowCode">
            <img src="../../../assets/img/code/qrcode9.png" alt />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryCodePicker from "../CountryCodePicker.vue";
import { mapMutations, mapState } from "vuex";
import {
  postUserPasswordLogin,
  postUserMe,
} from "../../../common/netWork/base_api";
import { doLogin, setJwt } from "../../../common/netWork/base";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
import regExp from "../regular.js";
const { phoneNumberRegExp } = regExp;

export default {
  name: "Login",
  inject: ["reload"],
  data() {
    return {
      conters: "+86",
      confirmDirty: false,
      tailFormItemLayout: {
        wrapperCol: {
          // xs: {
          //   span: 24,
          //   offset: 0,
          // },
          // sm: {
          //   span: 16,
          //   offset: 8,
          // },
        },
      },
      SignInData: {
        areaCode: "",
        phoneNumber: "",
      },
      ShowCode: true,
      // 这里是对应国家的国际区号，这里是必填项，例如中国是86
      countryCode: 86,
      phoneNumberValidateCallback: null,
      passwordValidateCallback: null,
      strings: strings,
    };
  },
  components: {
    CountryCodePicker,
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  mounted() {
    const phoneNumberTem = sessionStorage.getItem("phoneNumberTem");
    if (phoneNumberTem) {
      this.form.setFieldsValue({
        phoneNumber: phoneNumberTem,
      });
    }
  },
  methods: {
    ...mapMutations([
      "changeLogin",
      "userLogin",
      "setUserIfLogin",
      "showLoginModel",
      "setRefreshMyGroupList",
      "mindmapRightMenu",
    ]),
    getString(i) {
      return getString(i);
    },
    loginRequest(obj) {
      let that = this;
      return new Promise(function (resolve, reject) {
        postUserPasswordLogin(
          obj,
          (res) => {
            doLogin(res);
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    userMessage(obj = {}) {
      let that = this;
      return new Promise(function (resolve, reject) {
        postUserMe(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    onClick({ key }) {
      this.conters = key;
      // console.log(`Click on item ${key}`);
    },

    // 登录
    handleSubmitLogin(e) {
      e.preventDefault();
      if (localStorage.getItem("accept") == "false") {
        this.$message.error("请同意服务条款和隐私政策"); // <!-- internationalization
        return false;
      }
      this.SignInData.areaCode = "+" + this.countryCode;
      this.SignInData.phoneNumber = this.$refs.phoneNumber.value;
      const SignInData = this.SignInData;
      var _this = this;
      this.form.validateFieldsAndScroll(
        ["phoneNumber", "password"],
        { first: true },
        (err, values) => {
          if (!err) {
            values.areaCode = this.SignInData.areaCode;
            this.loginRequest(values).then((data) => {
              setJwt(data.jwt);
              this.$message.success(
                getString(strings.Message_Tips_Login_Success)
              );
              this.$emit('loginSucceededFun');
            });
          }
        }
      );
    },
    // 失去焦点
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },

    // 登录注册切换
    ClickLoginShow() {
      this.$emit("ClickLoginShow", "Sign");
    },
    // 邮箱登录
    mailboxLogin() {
      this.$emit("ClickLoginShow", "MailboxLogin");
    },
    // 忘记密码
    ForgetPassWorld() {
      // this.$router.push("/forGetPwd");
      if (this.$refs.phoneNumber.value != undefined) {
        window.localStorage.setItem(
          "uponenubers",
          this.$refs.phoneNumber.value
        ); //手机号传参
      }
      this.$emit("ClickLoginShow", "FindPassworld");
    },
    //验证码登录
    ForgetVerific() {
      // console.log("-------",this.$refs.phoneNumber.value)
      if (this.$refs.phoneNumber.value != undefined) {
        window.localStorage.setItem("uponenuber", this.$refs.phoneNumber.value); //手机号传参
      }
      this.$emit("ClickLoginShow", "Verifications");
    },
    // 密码验证
    validateToNextPassword(rule, value, callback) {
      this.passwordValidateCallback = callback;
      // LoginData.yz(rule, value, callback);
      // const form = this.form;
      const PassWordValue =
        /((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[#@!~%^&*])|(?=.*\d)(?=.*[#@!~%^&*]))[a-z\d#@!~%^&*]{8,24}/i;
      if (!(!PassWordValue.test(value) && value)) {
        callback();
      }
    },
    //密码验证焦点离开判断
    passwordBlurForValidate(e) {
      if (this.passwordValidateCallback == null) {
        return;
      }
      let value = this.$refs.password.value;
      const PassWordValue =
        /((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[#@!~%^&*])|(?=.*\d)(?=.*[#@!~%^&*]))[a-z\d#@!~%^&*]{8,24}/i;
      if (!PassWordValue.test(value) && value) {
        this.passwordValidateCallback(
          getString(strings.Input_The_Password_Format_Is_Incorrect)
        );
      } else {
        this.passwordValidateCallback();
      }
    },
    // 手机号验证
    phoneNumber(rule, value, callback) {
      this.phoneNumberValidateCallback = callback;
      if (value.substring(0, 2) == 90) {
        callback();
        return;
      }
      if ("" + this.countryCode == "86") {
        var iphoneNumber = phoneNumberRegExp;
        if (!(!iphoneNumber.test(value) && value)) {
          callback();
        }
      } else {
        if ((value + "").length > 4 && (value + "").length < 20) {
          callback();
        }
      }
    },
    //手机号验证焦点离开判断
    phoneNumberBlurForValidate(e) {
      let value = this.$refs.phoneNumber.value;
      if (this.phoneNumberValidateCallback == null) {
        return;
      }
      if (value.substring(0, 2) == 90) {
        this.phoneNumberValidateCallback();
        return;
      }
      if ("" + this.countryCode == "86") {
        var iphoneNumber = phoneNumberRegExp;
        if (!iphoneNumber.test(value) && value) {
          this.phoneNumberValidateCallback(
            getString(strings.Input_Please_Enter_A_Valid_Phone_Number)
          );
        } else {
          this.phoneNumberValidateCallback();
          sessionStorage.setItem("phoneNumberTem", value);
        }
      } else {
        if ((value + "").length <= 4 || (value + "").length >= 20) {
          this.phoneNumberValidateCallback(
            getString(strings.Input_Please_Enter_A_Valid_Phone_Number)
          );
        } else {
          this.phoneNumberValidateCallback();
          sessionStorage.setItem("phoneNumberTem", value);
        }
      }
    },
    // 二维码界面
    ClickShowCode() {
      this.$emit("ClickShowCode", !this.ShowCode);
    },
  },
};
</script>

<style scoped>
.Login-content {
  background-size: cover;
  /* width: 1900px; */
  min-height: 1920px;
  min-height: 99vh;
}

.hello {
  /* width: 600px; */
  min-width: 1200px;
  /* min-height: 99.9vh; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-content-box {
  height: 505px;
  position: relative;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: inherit;
  position: relative;
  background-color: #ffffff;
}

.login-right {
  margin-top: -80px;
}
.login-right .row {
  width: 363px;
  /* border: 1px red solid; */
}
.login-right .lright-cont {
  width: 94%;
  margin-left: 1em;
  min-width: auto;
  /* border: 1px rgb(0, 140, 255) solid; */
}

.login-right .lc-titel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lc-titel h2 {
  font-size: 24px;
  font-family: Microsoft YaHei;
  /*font-weight: bold;*/
  line-height: 31px;
  color: #404040;
  opacity: 1;
}
.sign-a {
  color: #fd492b;
  float: right;
  margin-right: 9px;
  line-height: 45px;
}
/*QR code*/
.Code-titel {
  width: 100%;
  text-align: center;
}
.Login-code {
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  cursor: pointer;
}

.Code-bottom-text {
  text-align: center;
}
.Code-bottom-text p {
  margin-top: 5px;
}

.Code-content img {
  width: 100px;
  height: 100px;
}
.Code-box {
  width: 100px;
  margin: 0 auto;
}
.login-content {
  margin: auto;
}

.login {
  height: 100%;
  position: relative;
  margin-top: 40px;
}

.Login-Button {
  width: 334px;
  height: 44px;
}
.checked-box {
  position: relative;
  top: -30px !important;
}
#register_agreement {
  border-color: red;
}
/* 更改 */
.ant-btn-primary {
  background-color: #fd492b !important;
  border-color: #fd492b !important;
  border-radius: 26px;
  font-size: 18px;
  /* margin-left: -8em; */
}
.ant-btn-primary:hover {
  background-color: #ff7354 !important;
  border-color: #ff7354 !important;
}
.mailbox-login {
  color: #666666;
  float: left;
}
.forgot-password {
  color: #666666;
  float: right;
}
.ho-a:hover {
  color: #ff7354;
}
.password-label {
  color: #fd492b;
  float: left;
}
/*模态框 */
.drop-down {
  position: absolute;
  z-index: 5;
  margin-top: 10px;
  margin-left: 15px;
  align-items: center;
}
.d-quhao {
  margin-top: 2px;
  display: flex;
}
.flag-list-box {
  background-color: #fff !important;
  width: 304px !important;
}
.login-content .phonr {
  padding-left: 56%;
}
.ant-select-selection {
  border: none;
  background: none;
}
.login-content .dr-deon {
  margin: 8px;
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 20px;
  color: #333333;
}
.login-content .lbtwo {
  margin-bottom: unset;
}
</style>

<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: red;
  border: none;
  color: rgba(0, 0, 0, 0.8);
}
.ant-form-item {
  margin-bottom: 24px;
}
.login-content .ai-group:hover {
  border: 1px #555 solid;
}
.ant-input:focus {
  border: 1px #555 solid;
  box-shadow: unset;
}
/* .flag-list-box {
  background-color: #fff !important;
  width: 304px !important;
} */
</style>
<style lang="less" scoped>
@media screen and(min-width:250px) and(max-width:500px) {
  .flag-list-box {
    width: 258px !important;
  }
  .login-content-box {
    height: 100%;
  }
  .Login-header {
    width: 100%;
    position: static;
  }
  .hello {
    width: 100%;
    min-width: 0;
    .login-right {
      padding: 0;
      margin-top: 0;
      margin: 0 auto;
      width: 100%;
      .row {
        width: 100%;
      }
      .lright-cont {
        margin: 10px;
      }
    }
  }

  .get-back {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    font-size: 16px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .login-content {
    padding-top: 0;
    .login {
      margin-top: 15px;
      .ant-form {
        width: 100%;
        .ant-form-item {
          .ant-col {
            .ant-form-item-control {
              .ant-form-item-children {
                .dr-agroup {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .lbtwo {
      padding-top: 0px;
      height: 23px;
    }
  }
  .Login-Button {
    height: 48px;
    margin-top: 11px;
    width: 100%;
  }
  .lc-titel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    h2 {
      line-height: 42px;
      font-size: 18px;
    }
    .lc-titel-a {
      width: auto !important;
    }
  }
}
</style>
