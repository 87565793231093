var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-content-box"},[_vm._m(0),_c('div',{staticClass:"login-right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"Login-header"},[_c('div',{staticClass:"Login-container"},[_c('div',{staticClass:"lc-titel"},[_c('h2',[_vm._v(_vm._s(_vm.getString(_vm.strings.User_Lolgin_Register_An_Account)))]),_c('div',{staticClass:"lc-titel-a",staticStyle:{"width":"71%"}},[_c('span',{staticClass:"lc-zi"},[_vm._v(_vm._s(_vm.getString(_vm.strings.User_Lolgin_Already_Have_An_Account))+"， "),_c('a',{staticClass:"sign-a",attrs:{"href":"javascript:;"},on:{"click":_vm.ClickLoginShow}},[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.User_Lolgin_To_Log_In))+" "),_c('a-icon',{attrs:{"type":"double-right"}})],1)])])])])]),_c('div',{staticClass:"lright-cont"},[_c('div',{staticClass:"login-content"},[_c('div',{staticClass:"login"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmitSignIn}},[_c('div',{staticClass:"drop-down d-quhao"},[_c('img',{staticStyle:{"margin-bottom":"2px"},attrs:{"src":require("../../../assets/img/code/phone_number_logo.png"),"alt":""}}),_c('CountryCodePicker',{model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'phoneNumber',
                    {
                      rules: [
                        {
                          validator: _vm.phoneNumber,
                        },
                        {
                          trigger: 'change',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'phoneNumber',\n                    {\n                      rules: [\n                        {\n                          validator: phoneNumber,\n                        },\n                        {\n                          trigger: 'change',\n                        },\n                      ],\n                    },\n                  ]"}],ref:"phoneNumber",staticClass:"login-phone-number",attrs:{"oninput":"if(value.length>11)value=value.slice(0,11);value=value.replace(/[^\\d]/g,'')","placeholder":_vm.getString(_vm.strings.User_Lolgin_Please_Enter_Phone_Number),"allow-clear":""},on:{"blur":_vm.phoneNumberBlurForValidate}})],1),_c('div',{staticClass:"drop-down"},[_c('img',{attrs:{"src":require("../../../assets/img/code/captcha1220.png"),"alt":""}})]),_c('a-form-item',{staticClass:"drcode"},[_c('div',{staticClass:"dr-agroup"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'captcha',
                      {
                        rules: [
                          {
                            message: _vm.getString(
                              _vm.strings.Input_Please_Enter_Verification_Code
                            ),
                          },
                          {
                            validator: _vm.validataToCode,
                          },
                        ],
                      },
                    ]),expression:"[\n                      'captcha',\n                      {\n                        rules: [\n                          {\n                            message: getString(\n                              strings.Input_Please_Enter_Verification_Code\n                            ),\n                          },\n                          {\n                            validator: validataToCode,\n                          },\n                        ],\n                      },\n                    ]"}],ref:"CodeValue",attrs:{"oninput":"if(value.length>6)value=value.slice(0,6);value=value.replace(/[^\\d]/g,'')","placeholder":_vm.getString(
                        _vm.strings.User_Lolgin_Please_Enter_Verification_Code
                      ),"allow-clear":""},on:{"blur":_vm.captchaBlurForValidate}}),_c('div',{on:{"click":_vm.handleSubmitCode}},[(_vm.showTimeData)?_c('a-button',{staticClass:"dra-btn scode",attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.timeData)+" "),_c('span',[_vm._v("s")])]):_c('a-button',{staticClass:"dra-btn"},[_vm._v(_vm._s(_vm.getString(_vm.strings.User_Lolgin_Obtain)))])],1)],1)]),_c('div',{staticClass:"drop-down"},[_c('img',{attrs:{"src":require("../../../assets/img/code/pwd_input_logo.png"),"alt":""}})]),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'password',
                    {
                      rules: [
                        {
                          message: _vm.getString(
                            _vm.strings.Input_Please_Enter_The_Password
                          ),
                        },
                        {
                          validator: _vm.validateToNextPassword,
                        },
                      ],
                    },
                  ]),expression:"[\n                    'password',\n                    {\n                      rules: [\n                        {\n                          message: getString(\n                            strings.Input_Please_Enter_The_Password\n                          ),\n                        },\n                        {\n                          validator: validateToNextPassword,\n                        },\n                      ],\n                    },\n                  ]"}],ref:"password",attrs:{"oninput":"if(value.length>24)value=value.slice(0,24)","placeholder":_vm.getString(_vm.strings.User_Lolgin_Please_Enter_Password),"allow-clear":"","type":"password"},on:{"blur":_vm.passwordBlurForValidate}})],1),_c('div',{staticClass:"drop-down"},[_c('img',{attrs:{"src":require("../../../assets/img/code/pwd_input_logo.png"),"alt":""}})]),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'confirm',
                    {
                      rules: [
                        {
                          message: _vm.getString(
                            _vm.strings.Input_Confirm_The_New_Password
                          ),
                        },
                        {
                          validator: _vm.compareToFirstPassword,
                        },
                      ],
                    },
                  ]),expression:"[\n                    'confirm',\n                    {\n                      rules: [\n                        {\n                          message: getString(\n                            strings.Input_Confirm_The_New_Password\n                          ),\n                        },\n                        {\n                          validator: compareToFirstPassword,\n                        },\n                      ],\n                    },\n                  ]"}],ref:"confirm",attrs:{"oninput":"if(value.length>24)value=value.slice(0,24)","placeholder":_vm.getString(_vm.strings.User_Lolgin_Confirm_Password),"allow-clear":"","type":"password"},on:{"blur":_vm.confirmBlurForValidate}})],1),_c('div',{staticClass:"drop-down"},[_c('img',{attrs:{"src":require("../../../assets/img/code/Invitation_code_logo.png"),"alt":""}})]),_c('div',{staticClass:"drop-down-before"},[_vm._v(_vm._s(_vm.getString(_vm.strings.User_Lolgin_Optional)))]),_c('a-form-item',[_c('div',{staticClass:"dr-agroup"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'invitation',
                      {
                        rules: [
                          {
                            required: true,
                            message: _vm.getString(
                              _vm.strings.User_Lolgin_Please_Correct_Enter_Invitation_Code
                            ),
                          },
                          {
                            validator: _vm.invitationToCode,
                          },
                        ],
                      },
                      (_vm.maxLength = 12),
                    ]),expression:"[\n                      'invitation',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: getString(\n                              strings.User_Lolgin_Please_Correct_Enter_Invitation_Code\n                            ),\n                          },\n                          {\n                            validator: invitationToCode,\n                          },\n                        ],\n                      },\n                      (maxLength = 12),\n                    ]"}],ref:"invitation",attrs:{"allow-clear":"","placeholder":_vm.getString(
                        _vm.strings.User_Lolgin_Please_Enter_Invitation_Code
                      )}})],1)]),_c('a-form-item',_vm._b({},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-button',{staticClass:"Login-Button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.User_Lolgin_Registered))+" ")])],1)],1)],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-content-icon-img"},[_c('img',{attrs:{"src":require("../../../assets/img/code/loginLog.png"),"alt":""}})])
}]

export { render, staticRenderFns }